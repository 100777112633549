$primary-color: #009696;

$font-color: #333333;
$background-color: #f0f2f5;
$font-gray-color: #757575;
$light-gray: #dbe0e4;

$status-green-color: #a1cd43;
$status-blue-color: #3b8ef3;
$status-orange-color: #f2c900;
$status-red-color: #ba1818;

$padding-common: 1.5em;
$padding-tab: 0 1em 1em 1em;
$padding-px: 16px;
$padding-big: 24px;

$font-family: 'FazzNeue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
	'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
	'Helvetica Neue', sans-serif;
$code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
