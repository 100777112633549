.card {
	margin-bottom: 1px;
}

.lastEvent {
	margin-top: 30px;
	transition: all 0.3s;
}

.titleBig {
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
}

.activity {
	margin: 2px 0 0 40px;
	color: #009696;
	display: flex;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	cursor: pointer;

	svg {
		margin: 0px 10px;
		transition: all 0.3s;
	}

	p {
		width: 90px;
	}

	.rotate90 {
		svg {
			transform: rotate(180deg);
		}
	}
}
