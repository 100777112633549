.pagination-query {
  .ant-pagination-item {
    background-color: transparent;
    border-color: transparent;
  }

  .ant-pagination-item-active {
    border-radius: 50%;
    border-color: white;
    background-color: #009696;

    a {
      color: white;
    }
  }
}
