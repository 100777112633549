.title {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
}

.card {
	margin-bottom: 1px;
}

.block {
	display: block;
}
