@import '../../styles/modules/responsive';

.app-table__table-container {
	background: white;
}

.app-table__pagination {
	margin-top: 1em;
	float: right;
	span {
		margin-right: 1em;
	}
}

.table-responsive {
	@include respond-to(xs) {
		overflow-x: auto;
		white-space: nowrap;
	}
	@include respond-to(sm) {
		overflow-x: auto;
		white-space: nowrap;
	}
	@include respond-to(md) {
		overflow-x: auto;
		white-space: nowrap;
	}
}
