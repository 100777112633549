.image-viewer {
  .displayed-image {
    position: relative;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    background-color: #333333;
    width: 300px;
    height: 300px;
    .rotate-button {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      padding: 0 8px;
      margin: 4px;
      .anticon {
        &-undo {
          font-size: 20px;
        }
        &-redo {
          font-size: 20px;
        }
      }
    }
    img {
      max-width: 300px;
      max-height: 300px;
      height: auto;
      margin: 0 auto;
      &:hover {
        cursor: zoom-in;
      }
    }
  }
}
.image-viewer-popup {
  width: 100%;
  overflow: hidden;
  .ant-modal {
    top: 80px;
    width: 50% !important;
    .ant-modal-header {
      background-color: transparent;
    }
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
      .ant-modal-body {
        width: 100%;
        height: 465px;
        margin: 0 auto;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
          user-select: none;
          &:hover {
            cursor: grab;
          }
          &:active {
            cursor: grabbing;
          }
        }
      }
      .ant-modal-footer {
        border-top: none;
        margin-top: -28px;
        .image-viewer-toolbar {
          display: flex;
          justify-content: center;
          padding: 0px;
          .anticon {
            &-zoom-in {
              transform: translateX(1px) translateY(3.5px);
              font-size: 20px;
            }
            &-zoom-out {
              transform: translateX(1px) translateY(3.5px);
              font-size: 20px;
            }
            &-undo {
              transform: translateX(1px) translateY(3.5px);
              font-size: 20px;
            }
            &-redo {
              transform: translateX(1px) translateY(3.5px);
              font-size: 20px;
            }
            &-column {
              &-width {
                transform: translateX(1px) translateY(3.5px);
                font-size: 20px;
              }
              &-height {
                transform: translateX(1px) translateY(3.5px);
                font-size: 20px;
              }
            }
          }
        }
        .toolbar {
          position: fixed;
          right: 0;
          bottom: 0;
          left: 0;
          overflow: hidden;
          text-align: center;
          height: 100px;
          ul {
            list-style: none;
            overflow: hidden;
            li {
              z-index: 10010;
              display: inline-block;
              background-color: rgba(0, 0, 0, 0.5);
              color: #fff;
              width: 28px;
              height: 28px;
              border-radius: 28px;
              margin-right: 3px;
              cursor: pointer;
              line-height: 28px;
            }
          }
        }
      }
      .ant-modal-close-x {
        display: none;
      }
    }
  }
}
