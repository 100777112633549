.table {
	// background-color: #eee;

	td {
		padding-top: 8px;
		padding-bottom: 0px;
		padding-right: 8px;
	}

	.titleTable {
		width: 150px;
	}
}

.loading {
	width: 100%;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
