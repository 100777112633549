.h-48px {
  .ant-calendar-picker-input.ant-input.ant-input-lg {
    height: 48px;
  }
}

.ant-calendar-range-picker-separator {
  padding-top: 7px;
}

@media (max-width: 576px) {
  .ant-calendar-range {
    width: 320px;
  }
  .ant-calendar-range-part {
    width: 100%;
  }
}
