@tailwind base;
@tailwind components;
@tailwind utilities;

@import './modules/global';
@import './modules/override';
@import './modules/normalize';

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-Regular.woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-Italic.woff2');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-Bold.woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-BoldItalic.woff2');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-Medium.woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FazzNeue';
	src: url('../assets/fonts/FazzNeue-MediumItalic.woff2');
	font-weight: 500;
	font-style: italic;
}

.app-content__container {
	// padding-bottom: 1.5em;
	display: flex;
	flex-direction: column;
}

.app-content {
	margin: $padding-px;
	&.--tabs {
		margin: 0 $padding-px $padding-px $padding-px;
	}
}

.app-content__details {
	margin: $padding-px;
	// div {
	// 	margin-bottom: 0.5em;
	// }
	label {
		font-size: 1.1rem;
		color: $font-gray-color;
	}
}
.ant-collapse-content,
.ant-collapse-item {
	background-color: white;
}

.custom__tabs {
	.ant-tabs-nav-scroll {
		text-align: center;
	}
	.ant-tabs-nav-container {
		background-color: white;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	}
	.ant-tabs-nav-scroll {
		background: white;
		border-top: solid 1px #ebedf8;
	}
}

.small-text {
	font-size: 1.1rem !important;
}

.flex {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.flex-space-between {
	@extend .flex;
	justify-content: space-between;
}

.flex-center {
	@extend .flex;
	justify-content: center;
}

.flex-end {
	@extend .flex;
	justify-content: flex-end;
}

.flex-start {
	@extend .flex;
	justify-content: flex-start;
}

.--no-wrap {
	flex-wrap: nowrap;
}

.app-tag {
	border: none;
	color: white;
	&.--inreview,
	&.--inprogress {
		background-color: #ffc53d;
	}
	&.--completed,
	&.--approved {
		background-color: #3b8ef3;
	}
	&.--rejected {
		background-color: #ba1818;
	}
	&.--expired {
		background-color: #757575;
	}
	&.--canceled {
		background-color: #ffb72a;
	}
	&.--completed-edited {
		background-color: #2dc4f8;
	}
}

#custom-scroll {
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		background-color: $light-gray;
	}
}

.full-width-button {
	width: 100%;
}

.block {
	margin-bottom: 1em;
}

.plain-detail-button {
	border: none;
	box-shadow: none;
	background-color: transparent;
	color: #3b8ef3;
	&:hover {
		background-color: transparent;
		color: #7caae2;
	}
}

.no-offside-content {
	min-width: 200px;
	max-width: 300px;
	word-break: break-word;
	white-space: normal;
}

.app-action-btn {
	margin: 0 0.5em;
	&.--left {
		margin-left: 0;
	}
	&.--right {
		margin-right: 0;
	}
}

.app-label {
	font-size: 1.1rem !important;
	color: $font-gray-color;
}

.app-ellipsis-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ant-table-column-title {
	font-weight: bold;
}
