.h-48px {
	.ant-calendar-picker-input.ant-input.ant-input-lg {
		height: 48px;
	}
}

@media (max-width: 576px) {
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
}