.custom-upload-style {
	&.--disabled {
		img {
			filter: grayscale(100%);
		}
		.ant-upload-drag-container {
			color: rgba(0, 0, 0, 0.45);
		}
		.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
			color: rgba(0, 0, 0, 0.45);
		}
		.ant-upload.ant-upload-drag p.ant-upload-text {
			color: rgba(0, 0, 0, 0.45);
		}
		.ant-upload-list-item-name {
			color: rgba(0, 0, 0, 0.45);
		}
	}
}
