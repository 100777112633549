.btn {
  width: 170px;
  left: 1233px;
  top: 138px;
  height: 48px;
  color: white;
  background: #009696;
  border-radius: 4px;
}

nav {
  float: left;
  width: 30%;
  background: white;
  padding: 20px;
  height: 100%;
}

article {
  float: left;
  padding: 20px;
  width: 70%;
  background-color: white;
}

.radioStyle {
  display: 'block';
  height: '30px';
}

.checkBox {
  column-count: 2;
}

.app-action {
  margin: 0 0.5em;
  &.--left {
    margin-left: 0;
  }
  &.--right {
    margin-right: 0;
  }
}
