.gridTemplate {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 2px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  div {
    background-color: #fff;
  }
}

.tag {
  height: 26px;
  width: 100px;
  border-radius: 50px;
  text-align: center;
  padding-top: 1px;
}
