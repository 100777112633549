.position-management__create-zone {
    .ant-form-item-label * {
        color: white !important;
    }

    .ant-form-item-required::before {
        color: white !important;
    }
}

.position-management__create-select-zone {
    margin: 4px 0;

    .ant-select-selection--multiple {
        max-height: 120px;
        overflow: auto;
    }
}