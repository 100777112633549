.position-management-detail {
    .ant-card-body {
        padding: 0;
    }
}

.position-management-detail__info {
    padding: 1.5em;
    border-bottom: 1px solid #dbe0e4;

    p {
        margin: 0;
    }
}

.position-management-detail__content,
.position-management-detail__edit {
    padding: 1.5em;
}

.position-management-detail__info-left {
    .ant-col {
        margin-right: 2.5em;
    }
}

.position-management-detail__actions {
    padding: 1.5em;
    border-top: 1px solid #dbe0e4;
}

.position-management-detail__edit {
    .ant-form-item {
        margin-bottom: 0;
    }
}

.position-management-detail__history {
    .ant-modal-header {
        border-bottom: 0;
        background: none;
    }

    .ant-modal-body {
        padding: 0;
    }
}

.position-management-detail-area-label {
    padding-right: 24px !important;
}

.position-management-detail-area-content {
    padding-left: 4px;
}