@import './global';

.ant-form-explain {
	float: left;
	min-height: 0px;
	font-size: 1.1rem;
}

.ant-drawer-body {
	padding: 24px 0;
	.ant-menu-item-selected > a,
	.ant-menu-item-selected > a:hover {
		color: $font-color;
	}
}

.ant-form-item-label {
	line-height: 1.5;
	label {
		font-size: 1.1rem !important;
		color: $font-gray-color;
	}
	.ant-form-item-required::before {
		font-size: 1.1rem !important;
	}
}

form .ant-mentions,
form textarea.ant-input {
	margin-top: 4px;
}

.ant-modal-title {
	font-weight: bold;
}
