@import '../../styles/modules/global';

.app-breadcrumb {
  background: white;
  border-top: solid 1px #ebedf8;
  padding: 0.5em $padding-px;
  i {
    margin-right: 1em;
  }
}
