@import '../../styles/modules/global';

.g-signin2 {
	.abcRioButton {
		width: 100% !important;
	}
	.abcRioButtonContentWrapper {
		display: flex;
		justify-content: center;
	}
}
